// Set the base path to the folder you copied Shoelace's assets to
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
setBasePath('@shoelace-style/shoelace/dist/');

// import each Shoelace component
import "@shoelace-style/shoelace/dist/components/card/card.js";
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/radio-group/radio-group.js';
import '@shoelace-style/shoelace/dist/components/radio/radio.js';
import '@shoelace-style/shoelace/dist/components/radio-button/radio-button.js';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import '@shoelace-style/shoelace/dist/components/details/details.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/carousel/carousel.js';


import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library';

registerIconLibrary('material', {
  resolver: name => {
    const match = name.match(/^(.*?)(_(round|sharp))?$/);
    return `https://cdn.jsdelivr.net/npm/@material-icons/svg@latest/svg/${match[1]}/${match[3] || 'outline'}.svg`;
  },
  mutator: svg => svg.setAttribute('fill', 'currentColor')
});

registerIconLibrary('default', {
  resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@latest/icons/${name}.svg`
});

