import {
  SlRadioGroup
} from "./chunk.6W5VKBP2.js";

// src/components/radio-group/radio-group.ts
var radio_group_default = SlRadioGroup;
SlRadioGroup.define("sl-radio-group");

export {
  radio_group_default
};
