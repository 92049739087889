import {
  SlRadioButton
} from "./chunk.R6DRYWZJ.js";

// src/components/radio-button/radio-button.ts
var radio_button_default = SlRadioButton;
SlRadioButton.define("sl-radio-button");

export {
  radio_button_default
};
