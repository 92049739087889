import {
  SlTextarea
} from "./chunk.EGN7PILG.js";

// src/components/textarea/textarea.ts
var textarea_default = SlTextarea;
SlTextarea.define("sl-textarea");

export {
  textarea_default
};
